// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-files-briefly-in-english-tsx": () => import("./../../../src/page-files/briefly-in-english.tsx" /* webpackChunkName: "component---src-page-files-briefly-in-english-tsx" */),
  "component---src-page-files-contact-tsx": () => import("./../../../src/page-files/contact.tsx" /* webpackChunkName: "component---src-page-files-contact-tsx" */),
  "component---src-page-files-index-tsx": () => import("./../../../src/page-files/index.tsx" /* webpackChunkName: "component---src-page-files-index-tsx" */),
  "component---src-page-files-kort-pa-svenska-tsx": () => import("./../../../src/page-files/kort-pa-svenska.tsx" /* webpackChunkName: "component---src-page-files-kort-pa-svenska-tsx" */),
  "component---src-page-files-pori-info-tsx": () => import("./../../../src/page-files/pori-info.tsx" /* webpackChunkName: "component---src-page-files-pori-info-tsx" */),
  "component---src-page-files-pori-join-crew-tsx": () => import("./../../../src/page-files/pori-join-crew.tsx" /* webpackChunkName: "component---src-page-files-pori-join-crew-tsx" */),
  "component---src-page-files-pori-partners-tsx": () => import("./../../../src/page-files/pori-partners.tsx" /* webpackChunkName: "component---src-page-files-pori-partners-tsx" */),
  "component---src-page-files-pori-tsx": () => import("./../../../src/page-files/pori.tsx" /* webpackChunkName: "component---src-page-files-pori-tsx" */),
  "component---src-page-files-pori-vastuullisuus-tsx": () => import("./../../../src/page-files/pori-vastuullisuus.tsx" /* webpackChunkName: "component---src-page-files-pori-vastuullisuus-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

